import React, { useEffect, useState, useMemo, createContext } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { BrowserUtils, EventType, InteractionStatus } from "@azure/msal-browser";
import { getAuthority } from "../msal/authConfig";
import { apiUrl } from "../utils/api";
import { Entities } from "../components/Entities";

const UserContext = createContext()

const getMsalStorageKey = (account) => {
    return account ? `${account.homeAccountId}-${account.environment}-` : null
}

const userStorageKey = "careers_portal_user_settings"

const setUserInMsalStorage = (accounts, data) => {
    const key = getMsalStorageKey(accounts.length ? accounts[0] : null)
    if (!key) return
    
    let value = JSON.parse(localStorage.getItem(key))
    let user = {}
    user[userStorageKey] = data
    localStorage.setItem(key, JSON.stringify({...value, ...user }))
}

const getUserFromMsalStorage = (accounts) => {
    const key = getMsalStorageKey(accounts.length ? accounts[0] : null)
    if (!key) return null
    
    let value = JSON.parse(localStorage.getItem(key))
    return value[userStorageKey]
}

const UserProvider = ({ children }) => {
    const {accounts, instance, inProgress} = useMsal()
    const isAuthenticated = useIsAuthenticated() 

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [userState, setUserState] = useState(null)
    const [fetchingUser, setFetchingUser] = useState(false)
    const [userFetched, setUserFetched] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)

    // User related util functions
    const get = (property=null, feature='user') => {
        if (!userState) return null
        //console.log(property, feature, userState)
        if (property) {
            return userState[feature][property] || null
        } else {
            return userState[feature] || null
        }
    }
    const set = (property, value, feature='user') => {
        if (userState){
            setUserState({
                ...userState,
                [feature]: {
                    ...userState[feature],
                    [property]:value
                }
            })
        }
    }

    // Memoize user
    const user = useMemo(() => ({ userState, isLoggedIn, get, set }), [userState, isLoggedIn, get, set])

    useEffect(() => {
        if (user.userState) {
            //console.log('user changed', user.userState)
            // Store user in msal storage
            setUserInMsalStorage(accounts, user.userState)

            // trigger google analytics
            const company = user.get('cof_company@OData.Community.Display.V1.FormattedValue') || null

            // Push new user data to Google Analytics dataLayer
            const dataLayer = window.dataLayer || [];
            dataLayer.push({event: 'userData', company})
            //console.log('pushed userData')
        }
    }, [userState])

    useEffect(() => {
        setIsLoggedIn(isAuthenticated && inProgress === InteractionStatus.None)
    }, [instance, accounts, inProgress])

    // Add extra settings after user is fetched via api
    useEffect(() => {
        if (userFetched) {
            const company = user.get('cof_company@OData.Community.Display.V1.FormattedValue') || null
            let { label = 'cofra'} = Entities.filter((e) => e.name === company)
            switch (label) {
                case 'cofra':
                case 'afam':
                case 'apwm':
                case 'anthos':
                    label = 'cofra'
                    break;
                default:
                    break;
            }
            set('companyLabel',label)
        }
    }, [userFetched])

    // Check if we have a user stored in msal local storage
    // If not, force sign-in
    useEffect(() => {
        if (isLoggedIn && !userState && !fetchingUser) {
            const userFromStorage = getUserFromMsalStorage(accounts)
            if (userFromStorage) {
                setUserState(userFromStorage)
            } else {
                // sign-out and redirect to sign-in
                const {tfp} = accounts[0].idTokenClaims
                instance.logoutRedirect({
                    authority: getAuthority(tfp),
                    postLogoutRedirectUri: `${process.env.GATSBY_SITE_URL}/sign-in`,
                    onRedirectNavigate: () => !BrowserUtils.isInIframe()
                })
            }
        }
    },[isLoggedIn])

    // On successful log in
    // Trigger google tag manager
    useEffect(() => {
        if (loggedIn && userState) {
            // trigger google analytics
            const company = user.get('cof_company@OData.Community.Display.V1.FormattedValue') || null

            // Push to Google Analytics dataLayer
            const dataLayer = window.dataLayer || [];
            dataLayer.push({event: 'userData', company})
            dataLayer.push({event: 'login_success', company})
            //console.log('pushed login_success and userData')
        }
    }, [loggedIn])

    // On msal login event, set user
    useEffect(() =>{
        const controller = new AbortController()
        const callbackId = instance.addEventCallback((message) => {
            if (message.eventType === EventType.LOGIN_SUCCESS) {

                // Fetch user
                let id = message.payload.account.username

                setFetchingUser(true)
                setUserFetched(false)
                fetch(`${apiUrl}/user/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    signal: controller.signal
                })
                .then((response) => {
                    setFetchingUser(false)
                    if (response.ok) {
                        return response.json()
                    } else if (response.status == '404') {
                        throw new Error('Error code 404: User not found.')
                    } else {
                        throw new Error(`Error code ${response.status}.`)
                    }
                })
                .then((data) => {
                    //console.log('user fetched on login success')
                    // Store user for the react application
                    setUserState(data)
                    setLoggedIn(true)
                    setUserFetched(true)
                })
                .catch((error) => {
                    console.error('Failed to fetch user. ', error)
                })
            }
        });
        // cleanup on unmount
        return () => {
            controller.abort()
            instance.removeEventCallback(callbackId)
        };
    }, [])

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
      );
}

export default UserContext;
export { UserProvider };
