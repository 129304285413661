import React, { useState } from "react";
import { Link } from "gatsby";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import NavMenu from "./NavMenu";
import useScrollBlock from "../../hooks/useScrollBlock";
import NavBarMenu from "./NavBarMenu";

const StyledDiv = styled.div`
    &:not(.minimal) .site-title {
        display: none;
    }
    &.minimal .site-slogan {
        display: none;
    }
    &.reveal-header-true {
        padding: 20px 0;

        & .site-title {
            display: block;
        }
        & .logos, & .site-slogan {
            display: none;
        }
    }

    --line-bg: #000000;
    --line-bg-opened: #ffffff;
    --line-width: 32px;
    --line-height: 3px;
    --transition: 300ms;
    & #menu-opener {
        position: relative;
        z-index: 999;
    }
    & .lines-button {
        display: inline-block;
        padding: 14px 7px;
        transition: var(--transition);
        cursor: pointer;
        user-select: none;
        border-radius: 4px;
        transform: scale(1.3);
        
        & .lines { 
            background: var(--line-bg);
            display: inline-block;
            width: var(--line-width);
            height: var(--line-height);
            transition: var(--transition);
            position: relative;
        
            &:before, &:after {
                height: 3px;
                display: inline-block;
                width: var(--line-width);
                height: var(--line-height);
                transition: var(--transition);
                background: var(--line-bg);
                position: absolute;
                left: 0;
                content: "";
                transform-origin: 2px center;
            }
            &:before {
                top: 9px;
                width: 16px;
                right: 0;
                left: initial;
            }
            &:after {
                top: -9px;
            }
        }
        &.opened .lines {
            background: transparent !important;
        
            &:before {
                transform: rotate3d(0, 0, 1, 45deg);
            }
            &:after {
                transform: rotate3d(0,0,1,-45deg);
            }
            &:before, &:after {
                transform-origin: 50% 50%;
                top: 0;
                width: var(--line-width);
                background: var(--line-bg-opened)
            }
        }
    }
`

const NavBarSignedIn = ({variant, showReveal}) => {

    const [openMenu, setOpenMenu] = useState(false)

    const [blockScroll, allowScroll] = useScrollBlock();

    const handleOpenMenu = (e) => {
        setOpenMenu(!openMenu);
        
        let searchBar=document.getElementById('search-bar')
        
        if (!openMenu) {
            blockScroll()
            if (searchBar) searchBar.style.zIndex=0
        } else { 
            allowScroll()
            setTimeout(()=> {if (searchBar) searchBar.style.zIndex=9999}, 200)
        }        
    }
    
    return (
        <>
            <StyledDiv
                className={`
                    ${showReveal ? 'reveal-header-true' : ''}
                    ${variant}
                `}
            >
                <Row className="">
                    <Col className="col-lg-6 d-flex">
                        <div className="brand align-self-center">
                            <Link to="/" className="text-black">
                                <h1 className="site-slogan display-3 m-0">
                                    Seeking development opportunities
                                </h1>
                                <h1 className="site-title font-family-primary font-size-7">
                                    COFRA Careers
                                </h1>
                            </Link>
                        </div>
                    </Col>
                    <Col className="button-col text-right">
                        <Row className="justify-content-end">
                            <Col className="col-auto align-items-center p-0 mr-10 d-none d-lg-flex">
                                <NavBarMenu />
                            </Col>
                            <Col className="col-auto">
                                <a 
                                    id="menu-opener" 
                                    className={`lines-button ${openMenu ? 'opened' : ''}`} 
                                    href="#"
                                    onClick={(e)=>{e.preventDefault(); handleOpenMenu()}}
                                >
                                    <span class="lines"></span>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <NavMenu openMenu={openMenu} handleOpenMenu={handleOpenMenu} />
            </StyledDiv>
        </>
    )
}

export default NavBarSignedIn