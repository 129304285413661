import React from "react";
import { Link } from "gatsby";
import { NavBarMenuItems } from "./NavBarMenuItems";
import Nav from 'react-bootstrap/Nav';

const NavBarMenu = (props) => {
    return (
        <>
            <Nav>
            { NavBarMenuItems.map(item => (
                    <Nav.Item>
                        <Link
                            to={item.link}
                            className="ml-10 font-size-7 text-black"
                        >
                            {item.lable}
                        </Link>
                    </Nav.Item>
                ))
            }
            </Nav>
        </>
    )
}

export default NavBarMenu