export const Entities = [
    {
        name: "Anthos Fund & Asset Management",
        label: "afam",
        b2cPolicy: "B2C_1_signup_signin_cofra",
        active: true,
        blogUrl: "blog/anthos-fund-and-asset-management",
        externalUrl: "https://www.anthosam.com/",
        dataverseOptionId: 1
    },
    {
        name: "Anthos Family Office",
        label: "anthos",
        b2cPolicy: "B2C_1_signup_signin_cofra",
        active: true,
        blogUrl: "blog/anthos-family-office",
        externalUrl: "https://www.cofraholding.com/en/family/#anthosTheFamilyOffice",
        dataverseOptionId: 2
    },
    {
        name: "COFRA Holding",
        label: "cofra",
        b2cPolicy: "B2C_1_signup_signin_cofra",
        active: true,
        blogUrl: "blog/cofra-holding",
        externalUrl: "https://www.cofraholding.com/",
        dataverseOptionId: 4
    },
    {
        name: "Sunrock",
        label: "sunrock",
        b2cPolicy: "B2C_1_signup_signin_sunrock",
        active: true,
        blogUrl: "blog/sunrock",
        externalUrl: "https://sunrock.com/",
        dataverseOptionId: 5
    },
    {
        name: "Anthos Private Wealth Management",
        label: "apwm",
        b2cPolicy: "B2C_1_signup_signin_cofra",
        active: false,
        blogUrl: "blog/anthos-fund-and-asset-management",
        externalUrl: "https://www.anthosam.com/",
        dataverseOptionId: 3
    },
    {
        name: "C&A Europe",
        label: "canda",
        b2cPolicy: "B2C_1_signup_signin_canda",
        active: false,
        blogUrl: "",
        externalUrl: "",
        dataverseOptionId: 0
    }
]