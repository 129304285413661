import { useStaticQuery, graphql } from "gatsby"
import { sanitize } from "../utils/content"
import Contents from 'contents'
import MarkdownContents from 'markdown-contents';

export const useContent = () => {
    const {list:{nodes:content}} = useStaticQuery(graphql`
    {
        list: allSharePointPageContentList
        {
            nodes {
                data {
                    fields {
                        Section
                        Content
                    }
                }
            }
        }
    }`)

    const get = (id, stripTags = false, markdown = true) => {

        let result = content.filter((item) => item.data.fields.Section === id)
        result = result[0]?.data?.fields?.Content || id
    
        return sanitize(result, stripTags, markdown)
    }
    const getRaw = (id) => {
        let result = content.filter((item) => item.data.fields.Section === id)
        result = result[0]?.data?.fields?.Content || id
    
        return result
    }
    const getTOC = (id) => {
        let articles = []
        let markdownTOC = ''
        let markdown = content.filter((item) => item.data.fields.Section === id)
        markdown = markdown[0]?.data?.fields?.Content || id
        
        //const markdownContents = MarkdownContents(result);
        
        markdown = markdown.replace(/^```[\s\S]*?\n```/mg, function (match) {
            return '';
        });

        markdown.replace(/^(#+)(.*$)/mg, function (match, level, name) {
            level = level.length;
            name = name.trim();

            // create github compatible heading ids. The same way as 'showdown' does when option 'ghCompatibleHeaderId' is true
            const id = name
                .replace(/ /g, '-')
                // replace previously escaped chars (&, ¨ and $)
                .replace(/&amp;/g, '')
                .replace(/¨T/g, '')
                .replace(/¨D/g, '')
                // replace rest of the chars (&~$ are repeated as they might have been escaped)
                // borrowed from github's redcarpet (some they should produce similar results)
                .replace(/[&+$,\/:;=?@"#{}|^¨~\[\]`\\*)(%.!'<>]/g, '')
                .toLowerCase();

            articles.push({
                level: level,
                id, //_.trim(name.toLowerCase().replace(/[^\w]+/g, '-'), '-'),
                name: name
            });
        });
        
        markdownTOC = MarkdownContents.treeToMarkdown(Contents.tree(articles))
        return sanitize(markdownTOC, false, true);
    }

    return { get, getRaw, getTOC }
}
