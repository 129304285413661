import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { menuItems } from "./menuItems";

const StyledNavMenu = styled.nav`
    --transition: 300ms;

    visibility: visible;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 998;
    overflow: hidden;
    background-color: #000000;
    transform: translate3d(0px, -100vh, 0px);
    transition: transform var(--transition);

    &.opened {
        visibility: visible;
        transform: translate3d(0px, 0px, 0px);
    }
`

const NavMenu = ({openMenu, handleOpenMenu}) => {

    return (
        <StyledNavMenu className={`navmenu text-white ${openMenu ? 'opened' : ''}`}>
            <Container className="h-100 align-items-center d-flex justify-content-center">
                <Row>
                    <Col className="">
                        <ul id="main-nav-menu" className="widget-links list-unstyled list-hover-primary">
                            {menuItems.map((item, i) => (
                                <li key={i} className="p-6">
                                    <Link 
                                        to={item.name} 
                                        title={`Navigate to ${item.label}`}
                                        onClick={handleOpenMenu}
                                        className="text-white font-size-10"
                                    >
                                        {item.label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </StyledNavMenu>
    )
}

export default NavMenu