import React from "react";
import Layout from "./src/components/Layout";
import wrapWithMsal from "./src/msal/wrap-with-msal";

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = wrapWithMsal;