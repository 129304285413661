export const apiUrl = process.env.GATSBY_API_URI

const apis = {
    "log/application" : {
        url: 'log/application',
        options: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: {}
        }
    }
}

export const api = async (operation, body) => {
    
    if (!apis[operation]) return Promise.reject('API does not exist')

    const {url, options} = apis[operation]
    
    if (body) options.body = JSON.stringify(body)
    
    const response = await fetch(`${apiUrl}/${url}`, options)
    
    return response.json()
}