import sanitizeHtml from "sanitize-html";
import parse from "html-react-parser";
import { decode } from "html-entities";

// Decode, sanitize and parse strings coming from external sources
export const sanitize = (str, notags=false, markdown=false) => {

    // convert markdown to html
    if (markdown) { 
        let showdown  = require('showdown')
        let converter = new showdown.Converter(
                {
                    ghCompatibleHeaderId: true
                }
            )
        str = converter.makeHtml(str)
    }

    str = sanitizeHtml(
            decode(str), 
            { 
                allowedTags: notags ? [] : [ 'p', 'b', 'i', 'em', 'strong', 'u', 'a', 'br', 'h1', 'h2', 'h3', 'ul', 'ol', 'li', 'blockquote', 'span' ], 
                allowedAttributes: {
                '*': [ 'href', 'align', 'alt', 'center', 'bgcolor', 'id' ]
                },
                exclusiveFilter: function(frame) {
                    return !['p','br'].includes(frame.tag) && !frame.text.trim();
                  }
            }
        )

    return parse(str)
}