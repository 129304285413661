import React, { useState, useRef, useContext } from "react";
import { Tooltip, Overlay } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import styled from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import ModalCloseButton from "../ModalCloseButton";
import GlobalContext from "../../context/GlobalContext";
import useAccount from "../../hooks/useAccount";

const FeedbackButton = styled.div`
    left: initial !important;
`

const MyTooltip = styled(Tooltip)`
    & .tooltip-inner {
        border: thin solid white;
    }
`

const Feedback = () => {
    
    const account = useAccount()
    const gContext = useContext(GlobalContext)
    const feedbackCookie = 'feedback-popup-declined'
    const [cookies, setCookie] = useCookies([feedbackCookie])

    const [show, setShow] = useState(false);
    const target = useRef(null);

    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < -300 && !(feedbackCookie in cookies)) {
            setShow(true);
        } 
    });

    // Extra options
    const expiry = 5 // days
    let expDate = new Date()
    expDate.setDate(expDate.getDate() + expiry);
    const options = {
        expires: expDate,
        path: '/'
    }

    const handlePopup = (e) => {
        e.preventDefault()
        setShow(!show)
    } 

    const handleClose = () => {
        setShow(false)
        setCookie(feedbackCookie, true, options)
    }

    if (!gContext.feedback.render) return (<></>)
    if (!account.isLoggedIn) return (<></>)

    return (
        <>
            <FeedbackButton className="fixed-bottom p-7">
                <a href="#" ref={target} onClick={handlePopup}>
                    <i className="fa fa-comments font-size-8"></i>
                </a>
            </FeedbackButton>
            <Overlay target={target.current} show={show} placement="top">
                {(props) => (
                <MyTooltip id="overlay-example" {...props}>
                    <ModalCloseButton onClick={handleClose} />
                    <div className="font-size-4 p-4">
                        Do you have a moment for some quick feedback?
                        <br />
                        <a 
                            href="https://forms.office.com/r/2NDwnD3H22" 
                            onClick={()=>{setShow(!show)}} 
                            target="_blank"
                        >
                                Yes, take me to the survey
                        </a>
                    </div>
                </MyTooltip>
                )}
            </Overlay>
        </>
    )
  }

export default Feedback