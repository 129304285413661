import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import GlobalContext from "../../context/GlobalContext";
import { device } from "../../utils";
import NavBarSignedOut from "./NavBarSignedOut";
import NavBarSignedIn from "./NavBarSignedIn";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

const SiteHeaderPlaceholder = styled.div`
  position: relative;
  display: none;

  &.reveal-placeholder {
    height: 300px;
    display: block;
  }
`

const SiteHeader = styled.header`
  .dropdown-toggle::after {
    opacity: 0;
  }
  padding: 10px 0 10px 0;
  position: relative !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 800;
  background-color: #FFECC6;
  //box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);

  & .brand .site-title {
      color: ${({ theme }) =>
      theme.colors.cofraBlack ? theme.colors.cofraBlack : "#1A1A1A" };
  }

  &.reveal-header {
    position: fixed !important;
    top: 0;
    z-index: 940 !important;
    padding-top: 0px !important;
    .header-btns {
      padding-top: 0 !important;
    }
    .header-inner { 
      padding-bottom: 0px !important; 
    }
  }
  
  @media ${device.lg} {
    //position: fixed !important;
    //transition: 0.6s;
    &.scrolling {
      //transform: translateY(-100%);
      //transition: 0.6s;
      padding-bottom: 0;
    }
    &.reveal-header {
      //transform: translateY(0%);
      box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
      z-index: 940;
      background: #FFECC6;
    }
  }
`;

const Header = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const gContext = useContext(GlobalContext);
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);
  
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
  });
  
  if (!gContext.header.render) return (<></>);

  return (
    <>
      <SiteHeaderPlaceholder className={`${showReveal ? 'reveal-placeholder' : ''}`} />
      <SiteHeader
        className={`site-header pt-8 align-items-start ${
          gContext.header.bgClass
        } ${
          gContext.header.align === "left"
            ? "site-header--menu-left "
            : gContext.header.align === "right"
            ? "site-header--menu-right "
            : "site-header--menu-center "
        }
        ${gContext.header.theme === "dark" ? "dark-mode-texts" : " "}
        ${showScrolling ? "scrolling" : ""}
        ${
          gContext.header.reveal &&
          showReveal &&
          gContext.header.theme === "dark"
            ? "reveal-header bg-blackish-blue"
            : gContext.header.reveal && showReveal
            ? "reveal-header"
            : ""
        }`}
      >
        <Container
          fluid={gContext.header.isFluid}
          className={`header-inner ${gContext.header.isFluid ? "pr-lg-9 pl-lg-9" : ""}`}
          style={{
            paddingBottom: gContext.header.paddingBottom+'px',
            maxWidth: '1920px'
          }}
        >
        { /* Logged out */ }
        {/* { 
          ( isAuthenticated && inProgress === InteractionStatus.None ) &&
            (<NavBarSignedOut showReveal={showReveal} />)
        } */}
        { 
          ( !isAuthenticated && inProgress === InteractionStatus.None ) &&
            (<NavBarSignedOut showReveal={showReveal} />)
        }
        { 
          ( isAuthenticated && inProgress === InteractionStatus.None ) &&
            (<NavBarSignedIn variant={gContext.header.variant} showReveal={showReveal} />)
        }
        </Container>
      </SiteHeader>
    </>
  );
};

export default Header;
