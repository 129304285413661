import React, { useContext } from "react";
import { Link, navigate } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { useCookies } from 'react-cookie';
import { useLocation } from "@reach/router"
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const CookieConsentBar = () => {
    
    const location = useLocation()
    const consentCookie = 'CofraCareersCookiesConsent'
    const cookieGA = 'gatsby-gdpr-google-analytics'
    const cookieTagManager = 'gatsby-gdpr-google-tagmanager'
    const gContext = useContext(GlobalContext)
    const [cookies, setCookie] = useCookies([consentCookie])

    // Extra options
    const expiry = 30 // days
    let expDate = new Date()
    expDate.setDate(expDate.getDate() + expiry);
    const options = {
        expires: expDate,
        path: '/'
    }
    
    const onAccept = () => {
        gContext.setVisibleCookieConsentBar(false)
        setCookie(consentCookie, true, options)
        //setCookie(cookieGA, true, options)
        setCookie(cookieTagManager, true, options)
        initializeAndTrack(location)
    }

    const onDecline = () => {
        gContext.setVisibleCookieConsentBar(false)
        setCookie(consentCookie, false, options)
        //setCookie(cookieGA, false, options)
        setCookie(cookieTagManager, false, options)
    }

    return (
        <>
            { !(consentCookie in cookies) && gContext.visibleCookieConsentBar &&
                <div 
                    className="w-100 fixed-bottom col-xxxl-6"
                    style={{ 
                        background: 'white', 
                        borderTop: 'thin solid silver', 
                        boxShadow: '0px 0px 20px rgb(0 0 0 / 20%)',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}
                >
                    <Container className="p-7 p-lg-10">
                        <Row>
                            <Col className="col-12 col-lg-7 col-xl-7">
                                <h2 className="font-family-primary font-size-7">This website uses cookies</h2>
                                <span className="text-black">We use cookies on this portal. These help us to keep the portal functional. Furthermore, some data is gathered for statistical reasons. We will not be able to identify you individually. You can find more detailed information about the cookies we use and instructions for how to withdraw any consent to the use of cookies or manage any other cookie preferences in our <Link to="/cookies-and-privacy">cookies and privacy statement</Link>.</span>
                            </Col>
                            <Col className="d-flex flex-column w-100 w-lg-auto p-6 justify-content-center mt-auto">
                                <Button onClick={onAccept}>Yes, I accept all cookies</Button>
                                <Button onClick={onDecline} variant="link">Only basic cookies, please →</Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </>
    )
  }

export default CookieConsentBar