export const menuItems = [
  {
    name: "/",
    label: "Home"
  },
  {
    name: "/search",
    label: "Vacancies"
  },
  {
    name: "/mentorship",
    label: "Mentorship"
  },
  {
    name: "/what-you-need-to-know",
    label: "What you need to know"
  },
  {
    name: "/contact",
    label: "Contact"
  },
  {
    name: "/sign-out",
    label: "Sign out"
  }
];
