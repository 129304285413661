import React from "react";
import { Link } from "gatsby";
import { Row, Col } from "react-bootstrap";
import useBreakpoint from "../../hooks/useBreakpoint";
import styled from "styled-components";

const StyledDiv = styled.div`
    & .button-col, & .site-title {
        display: none;
    }
    &.reveal-header-true {
        padding: 20px 0;

        & .button-col, & .site-title {
            display: block;
        }
        & .logos, & .site-slogan {
            display: none;
        }
    }
`

const NavBarSignedOut = ({showReveal}) => {
    
    return (
        <StyledDiv className={`${showReveal ? 'reveal-header-true' : ''}`}>
            <Row className="">
                <Col className="col-lg-6 d-flex">
                    <div className="brand align-self-center">
                        <Link to="/" className="text-black">
                            <h1 className="site-slogan display-3 m-0">
                                Seeking development opportunities
                            </h1>
                            <h1 className="site-title font-family-primary font-size-sm-7 font-size-4">
                                COFRA Careers
                            </h1>
                        </Link>
                    </div>
                </Col>
                <Col className="button-col text-lg-right">
                    <Link 
                        to="/sign-in"
                        className="btn btn-primary text-uppercase"
                        size={['xsm','sm','md'].includes(useBreakpoint()) ? 'sm' : 'lg'}
                    >
                        Sign in
                    </Link>
                </Col>
            </Row>
        </StyledDiv>
    )
}

export default NavBarSignedOut