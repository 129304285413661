import React from "react"

const ModalCloseButton = (props) => {

    const {onClick} = props;

    return (
        <button
            type="button"
            className="circle-32 btn-reset border-0 bg-white pos-abs-tr mt-md-n4 mr-lg-n4 focus-reset z-index-supper"
            onClick={onClick}
        >
            <i className="fas fa-times"></i>
        </button>
    )
}

export default ModalCloseButton