const b2cSignInUrl = process.env.GATSBY_APP_B2C_SIGN_IN_URL

const authorityDomain = process.env.GATSBY_APP_B2C_AUTHORITY_DOMAIN

export const getAuthority = (policy) => {
    return `${b2cSignInUrl}/${policy}`
}

export const msalConfig = {
    auth: {
        clientId: process.env.GATSBY_APP_B2C_ID, 
        
        // we don't need authority for MSAL initialization
        // will pass it later in loginRedirect()
        //authority: "https://cofrab2c.b2clogin.com/cofrab2c.onmicrosoft.com/",
        
        knownAuthorities: [authorityDomain],
        redirectUri: process.env.GATSBY_APP_B2C_REDIRECT_URI,
        postLogoutRedirectUri: process.env.GATSBY_APP_B2C_REDIRECT_URI,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false, 
    },
    system: {
        allowRedirectInIframe: true
    }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = (policy) => {
    return policy ? { 
        scopes: ["openid", "offline_access", "profile", process.env.GATSBY_APP_B2C_ID],
        authority: getAuthority(policy) || ""
    } : { 
        scopes: ["openid", "offline_access"],
    }
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/beta/me"
};