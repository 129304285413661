export const NavBarMenuItems = [
    {
        lable: "Vacancies",
        link: "/search",
        title: "See vacancies"
    },
    {
        lable: "Mentorship",
        link: "/mentorship",
        title: "Explore mentorship"
    }
]