import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';
import { breakpoints } from '../utils';

const getDeviceConfig = (width) => {
    let point = 'xsm'
    
    for (const p in breakpoints) {
        if (width >= breakpoints[p]) {
          point = p
        }
    }
    return point
};

const useBreakpoint = () => {

  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(typeof window === 'undefined' ? 1200 : window.innerWidth))
  
  useEffect(() => {
    const calcInnerWidth = throttle(function() {
      setBrkPnt(getDeviceConfig(typeof window === 'undefined' ? 1200 : window.innerWidth))
    }, 200); 
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', calcInnerWidth);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', calcInnerWidth)
      }
    }
  }, []);

  return brkPnt;
}
export default useBreakpoint;