export const breakpoints = {
  xsm: 375, //Mobile screen start
  sm: 576, //Small screen start
  md: 768, //Medium screen start
  lg: 992, //Large screen start
  xl: 1200, //Extra Large screen start
  xxl: 1400, //Extra extra Large screen start
};

export const device = {
  xsm: `(min-width: ${breakpoints.xsm}px)`, 
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  xxl: `(min-width: ${breakpoints.xxl}px)`,
};
