import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { InteractionStatus } from "@azure/msal-browser"

const setRoles = (roles = '') => {
    return roles.replaceAll().split(',')
}

const retrieveCustomAttribs = (pool = {}) => {
    let attribs = {}
    const properties = Object.getOwnPropertyNames(pool)
    // loop pool entries
    properties.forEach(p => {
        // starts with extension_?
        if (p.includes("extension_")) {
            // split by "_"
            const propName = p.split('_').pop()
            // store in the result
            // use last part as property name
            attribs[propName] = pool[p]
        }
    })
    
    return attribs
}

const useAccount = () => {

    const { accounts, inProgress } = useMsal()
    const isAuthenticated = useIsAuthenticated() 
    const isLoggedIn = isAuthenticated && inProgress === InteractionStatus.None
    const account = accounts.length ? accounts[0] : null
    const id = account?.localAccountId || null
    const claims = account?.idTokenClaims || {}
    const newUser = claims?.newUser || false
    const customAttribs = retrieveCustomAttribs(claims)

    // set entity
    let entity = '';
    if (isLoggedIn) {
        switch (claims.tfp) {
            case 'B2C_1_signup_signin_sunrock':
                entity = 'sunrock';
                break;
            case 'B2C_1_signup_signin_cofra':
                entity = 'cofra'
                break;
            default:
                entity = ''
                break;
        }
    }

    return {
        isLoggedIn: isLoggedIn,
        id,
        username: account?.username || '',
        entity,
        emails: claims?.emails || [],
        name: claims?.name || '',
        familyName: claims?.family_name || '',
        givenName: claims?.given_name || '',
        claims,
        ...customAttribs
    }
}

export default useAccount