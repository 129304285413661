import React, { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Link } from "gatsby";
import { useContent } from "../../hooks/useContent";
import styled from "styled-components";
import { device } from "../../utils";
import { Container, Row, Col } from "react-bootstrap";
import LogoAnthos from "../../assets/image/logo_anthos_white_baselined.svg"
import LogoAfam from "../../assets/image/logo_afam_white_baselined.svg"
import LogoCofra from "../../assets/image/logo_cofra_white_baselined.svg"
import LogoSunrock from "../../assets/image/logo_sunrock_white_baselined.svg"
import useAccount from "../../hooks/useAccount";

const logos = [
  {
    src: LogoAfam,
    href: 'https://www.anthosam.com/',
    title: 'See Anthos Fund and Asset Management website',
    alt: 'Logo Anthos Fund and Asset Management',
    class: 'logo-afam footer-logo',
    width: 100,
    wratio: 1
  },
  {
    src: LogoAnthos,
    href: 'https://www.cofraholding.com/en/family/#anthosTheFamilyOffice',
    title: 'See Anthos Family Office website',
    alt: 'Logo Anthos Family Office',
    class: 'logo-anthos footer-logo',
    width: 100,
    wratio: 1
  },
  {
    src: LogoCofra,
    href: 'https://www.cofraholding.com/',
    title: 'See COFRA Holding website',
    alt: 'Logo COFRA Holding',
    class: 'logo-cofra footer-logo',
    width: 100,
    wratio: 1
  },
  {
    src: LogoSunrock,
    href: 'https://sunrock.com/',
    title: 'See Sunrock website',
    alt: 'Logo Sunrock',
    class: 'logo-sunrock footer-logo',
    width: 100,
    wratio: 1
  }
]

const StyledFooter = styled.footer`
  & p {
    color: white;
  }
  & .footer-logo {
    color: white;
  }
  
  & .footer-logo {
    height: 60px;
    @media ${device.sm} {
      height: 50px !important;
    }
    @media ${device.md} {
      height: 60px !important;
    }
    @media ${device.lg} {
      height: 40px !important;
    }
    @media ${device.xl} {
      height: 50px !important;
    }
    @media ${device.xxl} {
      height: 50px !important;
    }
  }
`

const Footer = () => {
  const account = useAccount()
  const gContext = useContext(GlobalContext)
  const content = useContent()

  const menuItems = [
    {
      label: "Contact",
      link: "/contact",
      display: account.isLoggedIn
    },
    {
      label: "Privacy statement",
      link: "/cookies-and-privacy",
      display: true
    }
  ]
  
  if (!gContext.footer.render) return (<></>);
  
  return (
    <>
      <StyledFooter className="footer bg-black mt-auto">
        <Container className="container pt-12 pt-lg-19 pb-10 pb-lg-19">
          <Row className="row justify-content-between">
            <Col className="col-12 col-lg-5 mb-lg-0 mb-9">
              <div className="media mb-sm-11 mb-0">
                <div className="media-body pl-5">
                  <div className="text-lg-left text-center">
                    <h2 className="text-white font-size-8 mb-6">
                      { content.get('portal-title', true) }
                    </h2>
                    <Row className="d-flex align-items-end justify-content-between"> 
                      { logos.map((item) => (
                          <a className="w-100 w-sm-auto mb-10 mb-sm-0" href={item.href} title={item.title} target="_blank">
                            <img 
                              className={item.class} 
                              src={item.src} 
                              alt={item.alt} 
                              width="auto"
                              height="50px"
                              style={{maxHeight: '100px', ...item.style}} />
                          </a>
                        )
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6">
              <div className="footer-widget widget3 mb-sm-0 mb-10">
                {/* <!-- widget social menu start --> */}
                <ul className="widget-links pl-0 list-unstyled list-hover-primary">
                  { menuItems.map((item) => item.display && ( 
                    <li className="mb-0 text-sm-left text-center">
                      <Link
                        to={ item.link }
                        className="heading-default-color font-size-4 font-weight-normal text-white"
                        onClick={item.onClick !== 'undefined' ? item.onClick : null}
                      >
                        { item.label }
                      </Link>
                    </li>
                    )) }
                </ul>
                {/* <!-- widget social menu end --> */}
                <div className="footer-widget widget4 mb-sm-0 mt-10 mb-10">
                  <p className="widget-title font-size-4 text-white mb-md-8 mb-7 text-sm-left text-center">
                    { content.get('copyright-text') }
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </StyledFooter>
    </>
  );
}
export default Footer;
